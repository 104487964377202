import React from 'react';

interface PageHeaderProps {
    title: string;
    children?: React.ReactNode;
    alert?: React.ReactNode;
    height?: 10 | 20;
}

const PageHeader = ({
    title,
    children,
    alert,
    height = 20,
}: PageHeaderProps) => {
    const heightClass = 'min-h-' + height;

    return (
        <div className={`flex flex-col sm:flex-row sm:justify-between sm:items-center ${heightClass} mb-4 gap-4`}>
            <h1 className="text-2xl font-bold leading-tight mb-4 sm:mb-0">{title}</h1>

            {alert}

            <div className="flex flex-col gap-2 sm:flex-row sm:items-center page-header-buttons">
                {children}
            </div>
        </div>
    );
};

export default PageHeader;
