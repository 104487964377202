import {Fragment} from 'react';
import {getCountryName} from '@/utils/getCountryName';

interface AddressProps {
    address: {
        building?: string;
        street1?: string;
        street2?: string;
        city?: string;
        region?: string;
        postalCode?: string;
        countryCode?: string;
    };
}

const Address = ({address}: AddressProps) => {
    if (!address) return null;

    const {
        building,
        street1,
        street2,
        city,
        region,
        postalCode,
        countryCode,
    } = address;

    const addressLines = [
        building,
        street1,
        street2,
        `${city}, ${region} ${postalCode}`,
        countryCode && getCountryName(countryCode),
    ]
        .filter(Boolean)
        .map((line, index) => {
            return (
                <Fragment key={index}>
                    {line}<br/>
                </Fragment>
            );
        });

    return (
        <div>
            {addressLines}
        </div>
    );
};

export default Address;
